<template>
    <div class="wheel-container" ref="wheelContainer" style="opacity: 0;"></div>
  </template>
  
  <script>
  import { ref, onMounted, onUnmounted } from 'vue';
  import { Wheel } from "spin-wheel";
  import wheelImg from "../assets/wheel.svg";
  import ZingTouch from 'zingtouch';
  import tickerAudio from "../assets/ticker.mp3"
  import { useStore } from "vuex";
  
  export default {
    name: 'SpinWheel',
    props: {
        onSpinComplete: {
            type: Function,
            required: false
        }
    },
  
    setup(props) {
      const wheelContainer = ref(null);
      const audio = ref(null);
      const isSpinning = ref(false);

      const store = useStore();

      let wheel;
      const img = document.createElement("img");
      img.src = wheelImg;
      

      audio.value = new Audio(tickerAudio);

      const items = [
        {
            label: 'Psycho',
            value: 'psycho'
          },
          {
            label: 'Nice Person',
            value: 'nice'
          },
          {
            label: 'Adult Life',
            value: 'adult'
          },
          {
            label: 'Couples',
            value: 'couples'
          },
          {
            label: 'Spicy',
            value: 'spicy'
          },
          {
            label: 'Confidence and Charm',
            value: 'confidence'
          },
          {
            label: 'Quirky Habits',
            value: 'quirky'
          },
          {
            label: 'Being Rude',
            value: 'rude'
          },
          {
            label: 'Spicy Couple',
            value: 'spicy-couple'
          },
          {
            label: 'Places You have had sex',
            value: 'sex'
          },
      ];

      const registerSwipeEvent = () => {
        const container = wheelContainer.value;
        var zt = new ZingTouch.Region(document.body);
        zt.bind(container, 'swipe', function(e){
          let direction = 1
          if(e.detail.data[0].currentDirection >= 180 && e.detail.data[0].currentDirection <= 270)
          {
            direction = -1
          }

          if(!isSpinning.value)
          {
            spinWheel(direction)
          }
        }, false);
      }

      const handleVisibilityChange = () => {
        registerSwipeEvent()
      }
  
      onMounted(() => {
        // Ensure the container is selected after the DOM is mounted
        const container = wheelContainer.value;
  
        // Create the wheel in the container and initialize it with the props
        wheel = new Wheel( container);

        img.onload = () => {
          wheel.init({
            items,
            image: img,
            onRest: ({currentIndex}) => {
                //save to store
                store.dispatch('updateResult', items[currentIndex].value);

                audio.value.pause();
                if (props.onSpinComplete) {
                    setTimeout(() => {
                        props.onSpinComplete();
                    }, 500);
                }
                isSpinning.value = false
            },
            onSpin: () => {
              audio.value.currentTime = 0
              audio.value.play();
              isSpinning.value = true
            },
            isInteractive: false,
            debug: false  
          })
          container.style.opacity = 1;
        }

        registerSwipeEvent()

        document.addEventListener('visibilitychange', handleVisibilityChange);
      });

      onUnmounted(() => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      })

      const spinWheel = (direction = 1) => {
        if (wheel) {
            const randomIndex = Math.floor(Math.random() * items.length);
            wheel.spinToItem(randomIndex, 8000, true, 6, direction);
        }
      };
  
      return {
        wheelContainer,
        isSpinning,
      };
    }
  }
</script>

<style>
.wheel-container{
  width: 1000px;
  height: 1000px;
  position: absolute;
  left: 50%;
  top: -26px;
  transform: translateX(-50%);
  z-index: 99;
  transition: opacity 0.3s;
}
</style>