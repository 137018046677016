<template>
  <main id="main">
    <Logo />
    <router-view />
  </main>
</template>

<script>

  import Logo from "./components/Logo"
  import { onMounted } from 'vue';

  export default {
    name: "App",
    components:{
      Logo
    },
    setup() {
      onMounted(() => {

      })
    }
  };
</script>

<style>
  body{
    margin: 0;
    overflow: hidden;
    font-family: 'Inter', sans-serif;
  }
  
  /* p{
      font-size: 20px;
  } */
  *{
    box-sizing: border-box;
  }
  .text-center{
    text-align: center;
  }
  #main{
    max-width: 575px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-top: 2rem;
    height: 100vh;
    max-height: 100svh;
    overflow: hidden;
  }
  img{
    max-width: 100%;
  }

  @media screen and (max-height: 568px){
    #main{
      padding-top: 1.5rem;
    }
  }
</style>
