<template>
    <div class="install-icon text-center">
        <img class="home-main" src="../assets/install-app.svg" />
    </div>
    <div class="install-instructions text-center">
        <h4>Install the Tipsy Land App for a better user experience!</h4>
        <p>For the best experience add the Tipsy Land app to your home screen.</p>
        <p>No need to visit the app store we can do it here.</p>
    </div>
    <div class="install-btn text-center">
        <a @click="onInstallClick"><img class="home-install" src="../assets/install-btn.svg" /></a>
        <router-link to="/home">Continue in browser</router-link>
    </div>
    <InstallInstructions :device="device" :show="showActionSheet" @hide="handleHideActionSheet" />
</template>

<script>
    import { ref, onMounted } from 'vue';
    import InstallInstructions from "../components/InstallInstructions"

    export default {
        name: "InstallPage",
        components: {
            InstallInstructions
        },
        setup() {

            const showActionSheet = ref(false);
            const device = ref(null)

            const onInstallClick = () => {
                showActionSheet.value = true
            }

            const handleHideActionSheet = () => {
                showActionSheet.value = false
            }

            onMounted(()=>{
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;

                console.log("userAgent", userAgent)

                // Check if Android
                if (/android/i.test(userAgent)) {
                    device.value = 'android';
                }

                // Check if iOS
                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    device.value = 'ios';
                }

                if (device.value != "android" && device.value != "ios") {
                    device.value = 'desktop';
                }
            })

            return {
                onInstallClick,
                showActionSheet,
                handleHideActionSheet,
                device
            };
        },
        
    }
</script>

<style scoped>
    a{
        color: #0F71F9;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        text-decoration: none;
        margin-top: 1rem;
    }
    .install-btn{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3rem 0;
    }
    .install-btn a{
        cursor: pointer;
    }
    .install-icon{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .install-instructions{
        h4{
            margin-top: 0;
        }
    }
    h4{
        font-size: 24px;
    }
    p{
        font-size: 20px;
        
        &:last-child{
            margin: 0
        }
    }

    @media screen and (max-height: 740px) {
        .install-btn{
            padding: 1rem 0;
        }
        h4{
            font-size: 22px;
        }
        p{
            font-size: 18px;
        }
        .install-btn {
            img{
                max-height: 75px;
            }
        }
    }
</style>