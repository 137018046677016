<template>
    <div class="home-page-content">
        <img class="home-main" src="../assets/txt-home-main.svg" />
        <img class="home-spin" src="../assets/txt-home-spin.svg" />
    </div>
    <div class="wheel-wrapper">
        <img class="arrow-img" src="../assets/wheel-arrow.svg" />
        <SpinWheel @spin-complete="handleSpinComplete"  />
    </div>
</template>

<script>
import SpinWheel from "@/components/SpinWheel.vue";
import { useRouter } from 'vue-router';


export default {
    name: "HomePage",
    components: {
        SpinWheel
    },
    setup() {
        const router = useRouter();

        const goToResult = () => {
            router.push({ name: 'ResultPage' });
        };

        const handleSpinComplete = () => {
            goToResult()
        }

        return {
            goToResult,
            handleSpinComplete
        };
    }
};
</script>

<style scoped>
.home-page-content{
    text-align: center;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .home-main{
        max-height: 145px;
        max-width: 325px;
        margin: 0 auto;
    }
}
.wheel-wrapper{
    height: 500px;
    position: relative;
    cursor: grab;

    .arrow-img{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        top: -50px;
        z-index: 999;
    }
}

@media screen and (max-height: 667px){
    .home-page-content{
        .home-main{
            max-height: 110px;
        }
        .home-spin {
            position: relative;
            top: -10px;
        }
    }
}

@media screen and (max-height: 568px){
    .wheel-wrapper{
        height: 480px;
    }
    .home-page-content{
        .home-main{
            max-height: 90px;
        }
    }
}

@media screen and (min-height: 844px){
    .wheel-wrapper{
        height: 600px;
    }
}

@media screen and (min-height: 915px){
    .wheel-wrapper{
        height: 700px;
    }
}
</style>